@import url(https://fonts.googleapis.com/css?family=Arima+Madurai&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'open sans';
}

.heading {
  padding: 5px 0 5px 10px;
  box-shadow: 0 0 10px #999;
  background-color: white;
}
.vid {
  padding-top: 50px;
}

.msg {
  margin-top: 50px;
}

.iv {
  padding: 6px;
  border: 1px solid #cecece;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.img-div {
  border: 1px solid #cecece;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-bottom: 5px;
  margin-top: 5px;
}

.city-head {
  color: #212529;
}

.sec-head {
  font-family: 'Arima Madurai';
  color: #b5090e;
  padding-bottom: 5px;
}

.sec-head h3 {
  border-bottom: 1px solid;
}

.sabha-sec {
  padding-bottom: 10px;
}

.online {
  color: #aa5900 !important;
}

.card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

@media (max-width:576px ) {
  .device-img {
    width: 272px;
  }
}

@media (max-width:1024px) {
  .device_img {
    width: 320px;
  }
}

nav {
  display: inline-block !important;
}

.page-item .page-link {
  font-weight: bold;
  color: #aa5900;
}

.page-link:hover {
  color: #aa5900 !important;
}

.alert {
  font-weight: bold;
}

.alert-warning {
  color: #aa5900 !important;

}

